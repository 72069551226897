import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './Home';
import About from './About';
import Scene from './Scene';
import Themes from './Themes';
import People from './People';
import reportWebVitals from './reportWebVitals';
import { Router, Link } from '@reach/router';
import Catalogue from './Catalogue';
import Credits from './Credits';
import Landing from './Landing';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Landing path="/" default />
      <Home path="/home" />
      <About path="/about" />
      <Themes path="/themes" />
      <People path="/people" />
      <Scene path="/exhibit" />
      <Catalogue path="/catalogue" />
      <Credits path="/credits" />
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
