import { useState, lazy, Suspense } from 'react';
import arrowLeft from './assets/freccia-sx.svg';
import arrowRight from './assets/freccia-dx.svg';
import close from './assets/close-white.svg';
import { isMobileWithTablet } from './utils';

const GalleryViewer = ({ closeCallback }) => {
  const [index, setIndex] = useState(0);
  const [actualImage, setActualImage] = useState(null);
  import(`./assets/gallery/foto Introini${index + 2}.jpg`).then((image) => {
    setActualImage(image.default);
  });

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        margin: 'auto',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3,
      }}
    >
      <div onClick={() => setIndex((i) => (i < 0 ? 0 : i - 1))}>
        <img
          src={arrowLeft}
          style={{
            width: '50px',
            cursor: 'pointer',
            opacity: index === 0 ? 0.25 : 1,
          }}
        />
      </div>
      <div
        style={{
          height: isMobileWithTablet ? '30vh' : '70vh',
        }}
      >
        <Suspense fallback={null}>
          <img
            src={actualImage}
            style={{ height: '100%', pointerEvents: 'none' }}
          />
        </Suspense>
        <div style={{ color: 'white' }}>{index + 1} / 27</div>
      </div>
      <div onClick={() => setIndex((i) => (i < 26 ? i + 1 : 26))}>
        <img
          src={arrowRight}
          style={{
            width: '50px',
            cursor: 'pointer',
            opacity: index === 26 ? 0.25 : 1,
          }}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: isMobileWithTablet ? '18vh' : '5vh',
          right: 0,
          left: 0,
          margin: 'auto',
          width: '100px',
        }}
        onClick={() => closeCallback()}
      >
        <img src={close} style={{ width: '50px', cursor: 'pointer' }} />
      </div>
    </div>
  );
};

export default GalleryViewer;
