import React, { useEffect, useRef } from 'react';
import { useLoader } from 'react-three-fiber';
import { focalPoint, get3dPosition, getDimension } from './3dutils';
import { TextureLoader, DoubleSide } from 'three';
import { Html } from '@react-three/drei';

const Image3d = ({ width, height, x, y, z, src, openGalleryCallback }) => {
  const ref = useRef();
  const texture = useLoader(TextureLoader, src);
  const isMarker = true;
  const mappedPosition = get3dPosition(
    x + width / 2,
    y + height / 2,
    isMarker,
    -27,
  );
  const [_w, _h] = getDimension(width, height);

  useEffect(() => {
    //ref.current.lookAt(focalPoint);
  }, []);

  const triggerHover = () => {
    document.body.style.cursor = 'pointer';
  };

  const triggerLeave = () => {
    document.body.style.cursor = 'auto';
  };

  return (
    <mesh
      ref={ref}
      visible={true}
      position={mappedPosition}
      rotation={[0, Math.PI / 5.8, 0]}
      scale={[-1, 1, 1]}
      side={DoubleSide}
    >
      <planeGeometry attach="geometry" args={[_w, _h, 32]} />
      <meshStandardMaterial attach="material" map={texture} side={DoubleSide} />

      <Html position={[0, 2, 0]} zIndexRange={[1, 0]} zIndexRange={[1, 0]}>
        <div
          className={'plusIcon'}
          onClick={() => {
            openGalleryCallback();
          }}
          onPointerDown={() => {
            openGalleryCallback();
          }}
          style={{ cursor: 'pointer' }}
        />
      </Html>
    </mesh>
  );
};

export default Image3d;
