import React from 'react';
import { Link } from '@reach/router';

function People() {
  return (
    <div
      style={{ backgroundColor: '#458f8e', width: '100%', minHeight: '100%' }}
    >
      <Link to={'/home'}>
        <div className="backIcon">&#x2190;</div>
      </Link>

      <main>
        <div
          style={{
            color: 'white',
          }}
        >
          <h1>People</h1>
          <br></br>
          <a
            href="https://www4.ceda.polimi.it/manifesti/manifesti/controller/ricerche/RicercaPerDocentiPublic.do?EVN_PRODOTTI=evento&k_doc=1208&polij_device_category=DESKTOP&__pj0=0&__pj1=9d070760d6c393b41daf1c23cad39e6a"
            target="_blank"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            <h3>Luca Basso Peressut</h3>
          </a>
          <p>
            PhD, is Full Professor of Interior Architecture, Exhibition Design
            and Museography at Politecnico di Milano and Director of the
            International Workshop of Museography and Archaeology Villa
            Adriana-Premio Piranesi held at Tivoli and Rome since 2003. He was
            Project Coordinator of the Research Project Mela-European Museums in
            an Age of Migrations, funded by the European Commission within the
            Seventh Framework Programme. Among his recent publications:{' '}
            <i>
              Museums in an Age of Migrations: Questions, Challenges,
              Perspectives
            </i>{' '}
            (2012);
            <i>European Museums in the 21st Century: Vol. 1;2;3 </i>(2013);{' '}
            <i>
              Wandering in Knowledge: Inclusive Spaces Culture in an Age of
              Global Nomadism
            </i>{' '}
            (2016) .
          </p>
          <br></br>
          <br></br>
          <a
            href="https://polimi.academia.edu/MichelaBassanelli"
            target="_blank"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            <h3>Michela Bassanelli</h3>
          </a>

          <p>
            PhD, is a Post-doc Researcher and Assistant Coordinator of FARB
            project Interstices and Urban Fragilities: The Italian experience,
            the European Outlook, DAStU Department, Politecnico di Milano.
            Bassanelli’s research focuses mainly on domestic interiors, on
            museography and on preserving and diffusing collective memory and
            cultural identity. From 2016 she is member of <i>ARK</i> Magazine
            (IT). Among her publications:{' '}
            <i>Guida all’architettura di Bergamo</i> (2018),{' '}
            <i>Nestorio Sacchi Architetto</i>.{' '}
            <i>Esperienze di progetto e design nella Bergamo del Novecento</i>{' '}
            (2018), <i>Oltre il memoriale</i>.{' '}
            <i>Le tracce, lo spazio, il ricordo</i> (2015).
          </p>
          <br></br>
          <br></br>
          <a
            href="https://www.dastu.polimi.it/dettaglio-personale-docente/?teacherId=83"
            target="_blank"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            <h3>Bertrando Bonfantini</h3>
          </a>
          <p>
            PhD, is Full Professor of Urbanism and member of the board of the
            PhD Program in Urban Planning, Design, and Policy at Politecnico di
            Milano (DAStU). Deputy-editor of <i>Urbanistica</i>, the journal of
            the National Institute of Urban Planning, till 2017, he is currently
            editor of the journal <i>Territorio</i>. Among his publications the
            books:{' '}
            <i>Dentro l’urbanistica. Ricerca e Progetto, tecniche e storia </i>
            (2017), <i>Bologna</i>. <i>Leggere il nuovo piano urbanistico</i>{' '}
            (ed., with F. Evangelisti, 2009), <i>Bergamo</i>.{' '}
            <i>Piani 1880-2000</i> (2008), <i>Piani urbanistici in Italia</i>.
            <i>Catalogo e documenti dell’Archivio RAPu</i> (with P. Gabellini
            and G. Paoluzzi, 2007), <i>Milano incompiuta</i>.{' '}
            <i>Interpretazioni urbanistiche del mutamento </i> (ed., with M.
            Bolocan Goldstein, 2007),
            <i> Progetto urbanistico e città esistente</i> (2002) .
          </p>
          <br></br>
          <br></br>
          <a
            href="https://polimi.academia.edu/immaforino"
            target="_blank"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            <h3>Imma Forino </h3>
          </a>
          <p>
            PhD, is Full Professor of Interior Architecture and Exhibition
            Design and member of PhD Program board in Architecture, Urban and
            Interior Design at Politecnico di Milano. She is editor of{' '}
            <i>Op. cit. journal</i> and of “ii inclusive interiors” book series
            (Maggioli), and member of the Scientific Committee of <i>ARK</i>{' '}
            (IT), <i>AR: Architecture, Research</i> (SLO) and <i>Res Mobilis</i>{' '}
            (ES) journals and of “DiAP Print” book series (Quodlibet). Among her
            publications the books:{' '}
            <i>L’interno nell’interno: Una fenomenologia dell’arredamento</i>{' '}
            (2001), <i>Eames, design totale</i> (2002),{' '}
            <i>George Nelson, thinking</i> (2004),{' '}
            <i>Uffici: Interni arredi oggetti</i> (2011, 1st Award Biella
            Letteratura e Industria 2012 for essays). Her last book is{' '}
            <i>La cucina: Storia culturale di un luogo domestico</i> (Turin:
            Einaudi 2019).
          </p>

          {/* <a
            href="https://www.dastu.polimi.it/dettaglio-personale-docente/?teacherId=462"
            target="_blank"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            DASTU
          </a> */}
          <br></br>
          <br></br>
          <a
            href="https://polimi.academia.edu/MadalinaGhibusi"
            target="_blank"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            <h3>Madalina Ghibusi </h3>
          </a>
          <p>
            PhD, is a postdoctoral researcher and assistant coordinator of FARB
            project “Interstices and Urban Fragilities: The Italian experience,
            the European Outlook” DAStU Department, Politecnico di Milano. In
            2019 she collaborated in the PoliSocial Award research project
            “M.O.S.T. of Pioltello” and she was also a teaching assistant at the
            Interior Design Studio of the School of Architecture, Urban Planning
            and Construction Engineering of Politecnico di Milano. Among the
            books she co-edited: <i>Urban Design Ecologies</i> (Maggioli, 2018)
            and <i>Scales of Interiors: Parks, Gardens, Objects</i> (Maggioli,
            2019). Her researches focus on the cross-disciplinary connections
            between design, psychology and social sciences.
          </p>

          <br></br>
          <br></br>
          <a
            href="http://www.marcointroini.net/"
            target="_blank"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            <h3>Marco Introini</h3>
          </a>
          <p>
            Arch. and documentary photo maker of landscape and architecture. He
            teaches Photography of Architecture and Representation at
            Politecnico di Milano. He has been selected among the twenty most
            influential photographers of architecture of the last ten years.
            With the photographic project <i>Milano Illuminista</i> in 2015 he
            was selected by the “Fondo Malerba per la Fotografia”. Awarded in
            several national competitions, he has to his credit numerous
            publications, photographic exhibitions of architecture and
            landscape.
          </p>

          <br></br>
          <br></br>
          <a
            href="https://polimi.academia.edu/JacopoLeveratto"
            target="_blank"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            <h3>Jacopo Leveratto </h3>
          </a>
          <p>
            Arch and PhD, is a Lecturer in Interior Architecture at Politecnico
            di Milano. His research is focused on public space design, with
            special emphasis on urban habitability and the human dimension of
            planning. Among his publications:{' '}
            <i>Città personali: Interni urbani a misura d'uomo</i> (2015),{' '}
            <i>Città da abitare: La misura urbana dell'inclusività</i> (2017), e{' '}
            <i>From Within: Between Interior Architecture and Design</i> (2020).
          </p>

          <br></br>
          <br></br>

          <a
            href="https://www4.ceda.polimi.it/manifesti/manifesti/controller/ricerche/RicercaPerDocentiPublic.do?evn_didattica=evento&k_doc=147527&aa=2011&lang=IT&jaf_currentWFID=main"
            target="_blank"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            <h3>Pierluigi Salvadeo </h3>
          </a>
          <p>
            PhD, is Associate Professor of Interior Architecture and Exhibition
            Design and member of PhD Program board in Architecture, Urban and
            Interior Design at Politecnico di Milano. Author of several
            publications with national and international publishers.
            Participates in and curates many design workshops, both in Italy and
            abroad. Participates in and organises national and international
            conferences. Member of editorial boards of scientific series. He has
            participated in various national and international architecture
            competitions and awards, winning prizes and recognition. In 2018 he
            won the “Premio Compasso d'Oro”.
          </p>

          <br></br>
          <br></br>
          <a
            href="https://www4.ceda.polimi.it/manifesti/manifesti/controller/ricerche/RicercaPerDocentiPublic.do?EVN_PRODOTTI=evento&k_doc=156935&polij_device_category=DESKTOP&__pj0=0&__pj1=3ceced5f6adfa47530f859b01e0e2911"
            target="_blank"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            <h3>Luigi Spinelli </h3>
          </a>
          <p>
            Associate Professor in Architectural Design at the School of
            Architecture, Urban Planning and Construction Engineering of the
            Politecnico di Milano. A member of the teaching board of the AUID
            PhD course of the Department of Architecture and Urban Studies, he
            coordinates the Master of Science in{' '}
            <i>Architectural Design and History</i> in the Mantova Campus of the
            Politecnico di Milano. An editor of the magazine <i>Domus</i> from
            1986 to 2013, he has been a member of the scientific editorial board
            of <i>Territorio</i> since 2010, and deputy editor since 2019. Major
            publications include <i>Sede dell’A.M.I.LA. a Tremezzo</i> (1994),{' '}
            <i>José Antonio Coderch</i>. <i>La cellula e la luce</i> (2003),{' '}
            <i>Paolo Soleri</i>. <i>Paesaggi tridimensionali</i> (2006),{' '}
            <i>Gli spazi in sequenza di Luigi Moretti</i> (2012),{' '}
            <i>Architecture in Mantua</i>.
            <i>From the Palazzo Ducale to the Burgo Paper Mill</i> (2018) and{' '}
            <i>La visione soggettiva: progetti e paesaggi</i> (2019).
          </p>
        </div>
      </main>
    </div>
  );
}

export default People;
