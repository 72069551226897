import React, { Suspense, useRef, useState } from 'react';
import { Canvas, extend, useThree, useFrame } from '@react-three/fiber';
import ImageSphere from './ImageSphere';
import CaseViewer from './CaseViewer';
import MapViewer from './MapViewer';
import HelpViewer from './HelpViewer';
import Totem from './Totem';
import TotemMap from './TotemMap';
import { Html, OrbitControls } from '@react-three/drei';
import { Spring, useSpring } from 'react-spring';
import { a } from '@react-spring/three';
import Image3d from './Image3d';
import bn from './assets/bn_1.jpg';
import { Link } from '@reach/router';
import { isMobileWithTablet } from './utils';
import GalleryViewer from './GalleryViewer';

const places = [
  {
    address: 'Milano — Piazza Gorani',
    location: '45.464,9.180',
  },
  {
    address: 'Perugia — Edicola 518',
    location: '43.108,12.387',
  },
  {
    address: 'Favara (Ag) — Farm',
    location: '37.313,13.656',
  },
  {
    address: 'Santa Lucia di Serino — A Natural Spring',
    location: '40.870,14.876',
  },
];

const Loader = () => {
  return (
    <Html center style={{ fontSize: '3vw', color: 'white', zIndex: 999 }}>
      LOADING...
    </Html>
  );
};

function Container() {
  const randomIndex = Math.floor(Math.random() * 4);

  return <Scene randomIndex={randomIndex} />;
}

function Scene({ randomIndex }) {
  const [caseActive, setCaseActive] = useState(null);
  const [mapActive, setMapActive] = useState(false);
  const [helpActive, setHelpActive] = useState(false);
  const [galleryActive, setGalleryActive] = useState(false);

  const openCallback = (index) => {
    setCaseActive(index);
  };

  const openMapCallback = () => {
    setMapActive(true);
  };

  const openGalleryCallback = () => {
    setGalleryActive(true);
  };

  const { ambientLightIntensity, directionalLightIntensity } = useSpring({
    ambientLightIntensity:
      caseActive === null && mapActive === false && galleryActive === false
        ? 0.9
        : 0.5,
    directionalLightIntensity:
      caseActive === null && mapActive === false && galleryActive === false
        ? 0.35
        : 0.1,
    config: {
      mass: 1,
      tension: 30,
      friction: 50,
      precision: 0.0001,
      duration: 750,
    },
  });

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div className={isMobileWithTablet ? 'place-info mobile' : 'place-info'}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {places[randomIndex].address}{' '}
          {!isMobileWithTablet && (
            <>
              <div className="mapIcon" style={{ margin: '0 2px 0 10px' }}></div>
              {places[randomIndex].location}
            </>
          )}
        </div>
      </div>
      <div
        className={isMobileWithTablet ? 'global-info mobile' : 'global-info'}
      >
        <div>
          <Link to={'/home'} className="link">
            <div
              style={{
                color: 'white',
              }}
            >
              &#x2190; BACK
            </div>
          </Link>
        </div>
        <div>
          <div
            className="link"
            style={{
              color: 'white',
              cursor: 'pointer',
            }}
            onClick={() => setHelpActive(true)}
          >
            HELP
          </div>
        </div>
      </div>
      <Canvas
        style={{
          backgroundColor: '#458f8e',
        }}
        gl={{ powerPreference: 'high-performance' }}
        colorManagement={false}
        camera={{
          position: [0, 1.5, 10],
          near: 1,
          far: 1000,
          fov: 60,
        }}
        onCreated={({ gl }) => {}}
      >
        <a.ambientLight intensity={ambientLightIntensity} />
        <a.directionalLight intensity={directionalLightIntensity} />
        <Suspense fallback={<Loader />}>
          <ImageSphere randomIndex={randomIndex} />
          <Totem
            position={[0, -16, -50]}
            size={10}
            openCallback={openCallback}
            totemIndex={1}
            rotation={0 - 0.2}
          />
          <Totem
            position={[0, -16, -50]}
            size={10}
            openCallback={openCallback}
            totemIndex={2}
            rotation={Math.PI / 3 - 0.2}
          />
          <Totem
            position={[0, -16, -50]}
            size={10}
            openCallback={openCallback}
            totemIndex={3}
            rotation={(Math.PI / 3) * 2 - 0.2}
          />
          <Totem
            position={[0, -16, -50]}
            size={10}
            openCallback={openCallback}
            totemIndex={4}
            rotation={Math.PI - 0.2}
          />
          <Totem
            position={[0, -16, -50]}
            size={10}
            openCallback={openCallback}
            totemIndex={5}
            rotation={-(Math.PI / 3) * 2 - 0.2}
          />
          {/* <TotemMap
            position={[0, -16, -50]}
            size={10}
            openMapCallback={openMapCallback}
            totemIndex={1}
            rotation={-Math.PI / 3 - 0.2}
          /> */}
          <Image3d
            src={bn}
            x={36.9}
            y={40}
            width={10}
            height={15}
            openGalleryCallback={openGalleryCallback}
          />
        </Suspense>
        <OrbitControls
          enabled={
            caseActive === null &&
            mapActive === false &&
            galleryActive === false
              ? true
              : false
          }
          enablePan={true}
          enableZoom={false}
          enableDamping={true}
          dampingFactor={0.1}
          maxZoom={1}
          minZoom={1}
        />
      </Canvas>
      {caseActive && (
        <CaseViewer
          caseIndex={caseActive}
          closeCallback={() => {
            setCaseActive(null);
            setMapActive(false);
            setGalleryActive(false);
          }}
        />
      )}
      {/* {mapActive && (
        <MapViewer
          closeCallback={() => {
            setCaseActive(null);
            setMapActive(false);
            setGalleryActive(false);
          }}
        />
      )} */}
      {galleryActive && (
        <GalleryViewer
          closeCallback={() => {
            setCaseActive(null);
            setMapActive(false);
            setGalleryActive(false);
          }}
        />
      )}
      {helpActive && (
        <HelpViewer
          closeCallback={() => {
            setHelpActive(false);
            setCaseActive(null);
            setMapActive(false);
            setGalleryActive(false);
          }}
        />
      )}
    </div>
  );
}

export default Container;
