import React, { useState } from 'react';
import bottom from './assets/textures/bottom.jpg';
import quadrato from './assets/textures/quadrato.jpg';
import top from './assets/textures/top.jpg';
import { useLoader } from 'react-three-fiber';
import { TextureLoader, RGBAFormat, DoubleSide, Vector3 } from 'three';
import { Html } from '@react-three/drei';
import ReactPlayer from 'react-player';
import { texts, width, images, totemTexts } from './utils';
import closeGreen from './assets/close-green.svg';
import { isMobileWithTablet } from './utils';

const hasAudio = {
  '1a': null,
  '1b': 'https://soundcloud.com/paoloc/caso-2-interview',
  '2a': 'https://soundcloud.com/paoloc/caso-3-interview',
  '2b': null,
  '3a': 'https://soundcloud.com/paoloc/caso-5-interview',
  '3b': 'https://soundcloud.com/paoloc/caso-6-interview',
  '4a': null,
  '4b': null,
  '5a': null,
  '5b': 'https://soundcloud.com/paoloc/caso-10-interview',
};

const Totem = ({ position, openCallback, size, totemIndex, rotation }) => {
  const [info, setInfo] = useState(null);
  const [totemInfo, setTotemInfo] = useState(null);
  const bottomTexture = useLoader(TextureLoader, bottom);
  const logoTexture = useLoader(TextureLoader, images[totemIndex].logo);
  const quadratoTexture = useLoader(TextureLoader, quadrato);
  const scritta1Texture = useLoader(TextureLoader, images[totemIndex].scritta1);
  const scritta2Texture = useLoader(TextureLoader, images[totemIndex].scritta2);
  const scritta3Texture = useLoader(TextureLoader, images[totemIndex].scritta3);

  scritta1Texture.format = RGBAFormat;

  return (
    <group position={[0, -3, 0]} rotation={[0, rotation, 0]}>
      <mesh
        position={[position[0], -size * 4 + size * 4.3, position[2]]}
        rotation={[0, 0, 0]}
      >
        <boxBufferGeometry args={[size, size * 0.3, size]} />
        <meshStandardMaterial color="#fff" map={bottomTexture} />
      </mesh>

      {/* TESTI */}
      <mesh
        position={[
          position[0] - 1.5,
          -size * 4 + size * 4.3,
          position[2] + size / 2 + 0.2,
        ]}
        rotation={[0, 0, 0]}
      >
        <planeGeometry
          args={[
            size * width[totemIndex].scritta1.width,
            size * width[totemIndex].scritta1.height,
          ]}
        />
        <meshStandardMaterial map={scritta1Texture} transparent />
        <Html position={[-6.5, 0, 0]} zIndexRange={[1, 0]}>
          <div
            className={'infoIcon'}
            width={35}
            onClick={() => setTotemInfo(totemIndex)}
            onPointerDown={() => setTotemInfo(totemIndex)}
            style={{ cursor: 'pointer' }}
          />
        </Html>
        {totemInfo && (
          <Html position={[10, 17, 0]}>
            <div
              style={{
                borderRadius: '20px',
                border: '2px solid #458f8e',
                color: '#458f8e',
                width: isMobileWithTablet ? '280px' : '420px',
                backgroundColor: 'white',
                padding: '30px',
                position: 'relative',
              }}
            >
              <h3 style={{ marginTop: 0 }}>{totemTexts[totemInfo].text}</h3>
              <div style={{ fontSize: isMobileWithTablet ? '11px' : '16px' }}>
                {totemTexts[totemInfo].title}
              </div>
              <img
                alt="icon"
                src={closeGreen}
                width={50}
                onClick={() => setTotemInfo(null)}
                onPointerDown={() => setTotemInfo(null)}
                style={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  cursor: 'pointer',
                }}
              />
            </div>
          </Html>
        )}
      </mesh>

      <mesh
        position={[position[0], -size * 4 + size * 3.8, position[2]]}
        rotation={[0, 0, 0]}
      >
        <boxBufferGeometry args={[size, size * 0.7, size]} />
        <meshStandardMaterial color="#fff" map={logoTexture} />

        {info && (
          <Html position={[10, 17, 0]}>
            <div
              style={{
                borderRadius: '20px',
                border: '2px solid #458f8e',
                color: '#458f8e',
                width: isMobileWithTablet ? '280px' : '420px',
                backgroundColor: 'white',
                padding: '30px',
                position: 'relative',
              }}
            >
              <h3 style={{ marginTop: 0 }}>{texts[info].title}</h3>
              <div style={{ fontSize: isMobileWithTablet ? '11px' : '16px' }}>
                {texts[info].text}
              </div>
              {hasAudio[info] && (
                <ReactPlayer
                  url={hasAudio[info]}
                  width={isMobileWithTablet ? 220 : 350}
                  height={70}
                  style={{
                    position: 'relative',
                    marginTop: '30px',
                  }}
                  config={{
                    soundcloud: {
                      options: {
                        download: false,
                        show_artwork: false,
                        show_user: false,
                        sharing: false,
                        color: '#458f8e',
                        single_active: true,
                        buying: false,
                        show_playcount: false,
                      },
                    },
                  }}
                />
              )}

              <img
                alt="icon"
                src={closeGreen}
                width={50}
                onClick={() => setInfo(null)}
                onPointerDown={() => setInfo(null)}
                style={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  cursor: 'pointer',
                }}
              />
            </div>
          </Html>
        )}
      </mesh>
      <mesh
        position={[position[0], -size * 4 + size * 2.45, position[2]]}
        rotation={[0, 0, 0]}
      >
        <boxBufferGeometry args={[size, size * 2, size]} />
        <meshStandardMaterial color="white" />
      </mesh>
      <mesh
        position={[position[0], -size * 4 + size * 1.2, position[2]]}
        rotation={[0, 0, 0]}
      >
        <boxBufferGeometry args={[size, size / 2, size]} />
        <meshStandardMaterial map={bottomTexture} color="#fff" />
      </mesh>

      {/* TESTI */}
      <mesh
        position={[
          position[0] - (width[totemIndex].scritta2.x || 0),
          -size * 4 + size * 3,
          position[2] + size / 2 + 1,
        ]}
        rotation={[0, 0, 0]}
      >
        <planeGeometry
          args={[
            size * width[totemIndex].scritta2.width,
            size * width[totemIndex].scritta2.height,
          ]}
        />
        <meshStandardMaterial map={scritta2Texture} color="white" />

        <Html position={[-8, 2, 0]} zIndexRange={[1, 0]}>
          <div
            className={'infoIcon'}
            width={35}
            onClick={() => setInfo(totemIndex + 'a')}
            onPointerDown={() => setInfo(totemIndex + 'a')}
            style={{ cursor: 'pointer' }}
          />

          <div
            className={'plusIcon'}
            width={35}
            onClick={() => {
              openCallback(totemIndex * 2 - 1);
              setInfo(null);
            }}
            onPointerDown={() => {
              openCallback(totemIndex * 2 - 1);
              setInfo(null);
            }}
            style={{ cursor: 'pointer' }}
          />
        </Html>
      </mesh>

      <mesh
        position={[
          position[0],
          -size * 4 + size * 2.2,
          position[2] + size / 2 + 1,
        ]}
        rotation={[0, 0, 0]}
        // onClick={() => {
        //   openCallback(totemIndex + 1);
        //   setInfo(null);
        // }}
        // onPointerDown={() => {
        //   openCallback(totemIndex + 1);
        //   setInfo(null);
        // }}
        // onPointerOver={() => {
        //   document.body.style.cursor = 'pointer';
        //   //setInfo(totemIndex + 1);
        // }}
        // onPointerOut={() => {
        //   document.body.style.cursor = 'auto';
        //   //setInfo(null);
        // }}
      >
        <planeGeometry
          args={[
            size * width[totemIndex].scritta3.width,
            size * width[totemIndex].scritta3.height,
          ]}
        />
        <meshStandardMaterial map={scritta3Texture} color="white" />

        <Html position={[-8, 2, 0]} zIndexRange={[1, 0]}>
          <div
            className={'infoIcon'}
            width={35}
            onClick={() => setInfo(totemIndex + 'b')}
            onPointerDown={() => setInfo(totemIndex + 'b')}
            style={{ cursor: 'pointer' }}
          />

          <div
            className={'plusIcon'}
            width={35}
            onClick={() => {
              openCallback(totemIndex * 2);
              setInfo(null);
            }}
            onPointerDown={() => {
              openCallback(totemIndex * 2);
              setInfo(null);
            }}
            style={{ cursor: 'pointer' }}
          />
        </Html>
      </mesh>

      {/* BASE */}

      <mesh
        position={[position[0], -size * 4 + size * 1.01, position[2]]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <planeGeometry args={[size * 1.75, size * 1.75]} />
        <meshStandardMaterial map={quadratoTexture} side={DoubleSide} />
      </mesh>

      <mesh
        position={[position[0], -size * 4 + size * 0.8, position[2]]}
        rotation={[0, 0, 0]}
      >
        <boxBufferGeometry args={[size * 1.75, size * 0.4, size * 1.75]} />
        <meshStandardMaterial map={bottomTexture} />
      </mesh>
    </group>
  );
};

export default Totem;
