import React from 'react';
import { Link } from '@reach/router';
import { isMobileWithTablet } from './utils';

function Landing() {
  return (
    <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <div
        style={{
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
          textAlign: 'center',
        }}
      >
        <div
          style={{
            position: 'absolute',
            padding: '15px',
            display: 'flex',
            zIndex: 2,
          }}
        >
          <div className="landingTitle">
            Urban <br></br> Interstices<br></br> in Italy.
            <div className="landingSubTitle"> Design Experiences</div>
          </div>
        </div>

        <div
          style={{
            position: 'absolute',
            margin: '0 auto',
            fontSize: isMobileWithTablet ? '24px' : '1.7vw',
            fontWeight: 'bold',
            color: 'white',
            right: isMobileWithTablet ? 'auto' : '50px',
            left: isMobileWithTablet ? 50 : 'auto',
            top: isMobileWithTablet ? 'auto' : 50,
            bottom: isMobileWithTablet ? 50 : 'auto',
            pointerEvents: 'none',
          }}
        >
          Click anywhere to start
        </div>

        <Link to={'/home'}>
          <div className="landingMap" />
        </Link>
      </div>{' '}
    </div>
  );
}

export default Landing;
