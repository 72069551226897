import { isMobile, isTablet } from 'react-device-detect';

import scrittaCaso1A from './assets/textures/caso_1_top.png';
import scrittaCaso1B from './assets/textures/caso_1_1.jpg';
import scrittaCaso1C from './assets/textures/caso_1_2.jpg';
import scrittaCaso2A from './assets/textures/caso_2_top.png';
import scrittaCaso2B from './assets/textures/caso_2_1.jpg';
import scrittaCaso2C from './assets/textures/caso_2_2.jpg';
import scrittaCaso3A from './assets/textures/caso_3_top.png';
import scrittaCaso3B from './assets/textures/caso_3_1.jpg';
import scrittaCaso3C from './assets/textures/caso_3_2.jpg';
import scrittaCaso4A from './assets/textures/caso_4_top.png';
import scrittaCaso4B from './assets/textures/caso_4_1.jpg';
import scrittaCaso4C from './assets/textures/caso_4_2.jpg';
import scrittaCaso5A from './assets/textures/caso_5_top.png';
import scrittaCaso5B from './assets/textures/caso_5_1.jpg';
import scrittaCaso5C from './assets/textures/caso_5_2.jpg';

import logo1 from './assets/textures/group1.jpg';
import logo2 from './assets/textures/group2.jpg';
import logo3 from './assets/textures/group3.jpg';
import logo4 from './assets/textures/group4.jpg';
import logo5 from './assets/textures/group5.jpg';

export const isMobileWithTablet = isMobile
  ? isTablet
    ? window.innerWidth < window.innerHeight
      ? true
      : false
    : true
  : window.innerWidth < 900
  ? true
  : false;

export const images = {
  1: {
    logo: logo1,
    scritta1: scrittaCaso1A,
    scritta2: scrittaCaso1B,
    scritta3: scrittaCaso1C,
  },
  2: {
    logo: logo2,
    scritta1: scrittaCaso2A,
    scritta2: scrittaCaso2B,
    scritta3: scrittaCaso2C,
  },
  3: {
    logo: logo3,
    scritta1: scrittaCaso3A,
    scritta2: scrittaCaso3B,
    scritta3: scrittaCaso3C,
  },
  4: {
    logo: logo4,
    scritta1: scrittaCaso4A,
    scritta2: scrittaCaso4B,
    scritta3: scrittaCaso4C,
  },
  5: {
    logo: logo5,
    scritta1: scrittaCaso5A,
    scritta2: scrittaCaso5B,
    scritta3: scrittaCaso5C,
  },
};
export const texts = {
  '1a': {
    title: 'Laguna Viva',
    text: 'Laguna Viva [Living Lagoon] is a new free to access permanent saltmarsh garden at the Palazzo delle Zattere in the Sestiere di Dorsoduro in Venice designed by Assemble with We Are Here Venice. The garden is the first part of a long-term strategy to enable Palazzo delle Zattere to engage with the complexities of everyday life in Venice.  The garden is designed to enhance the on-going conversation about the intimate and mutually dependent relationship between Venice and its context. It is composed of a series of tiled tanks that house a typical example of the Venetian Lagoon’s saltmarsh habitat, arranged according to botanical associations and the morphology of the lagoon.',
  },
  '1b': {
    title: 'Giardino di Artemide',
    text: "The Garden of Artemis is part of a more complex project involving the area of San Sebastianello on Minerva street in Siracusa, where a pavilion was built to provide access to the excavations of the Ionic Temple, which were difficult to access since they were located below some municipal offices. The project suggests an intimate union between nature and artifice: the work, which takes on the value of a sort of raised path/recinct around the site's various differences in level, is made of corten steel retaining slabs and overlapping cages of electrowelded mesh filled with gravel, whose peculiar material qualities harmonise perfectly with the spontaneous nature that infests the area.",
  },
  '2a': {
    title: 'Sport Box',
    text: 'Sport Box is one of the five projects presented as part of the exhibition entitled Taking Care. Designing for the Common Good by TAM Associati (Venice Biennale, 2016), which proposed an idea of architecture as a tool at the service of the community and its access to shared resources. Five Italian firms, in collaboration with five associations involved in programmes aimed at combating social and environmental decline, were invited to design mobile architectures capable of responding to certain societal needs. NOWA’s Sport Box is engaged in promoting citizenship sport in the outskirts of cities and can be configured in different ways thanks to its adaptability. The association known as the “UISP-Unione Italiana Sport Per Tutti” makes use of this mobile object as a way of redeveloping underprivileged suburbs and areas by promoting integration and fostering a social campaign of inclusion through sport, in schools and local areas.',
  },
  '2b': {
    title: 'Prossima Apertura',
    text: 'The project of Prossima Apertura is showcasing a balance between a mainly bottom-up strategy achieved through temporary actions that leave permanent or significant changes which contribute to the long-term goals of the area, and so it has a rather lateral orientation. The multidisciplinary working group in Architecture and Urbanism (Orizzontale and ADML) Psycho-social research and Psychology (NOEO), Communication (Mara Zamuner), Arts (Mimmo Rubino), Photography (Alessandra Imbriaco, Alessandro Vitali) and Video (Nicola Barbuto) joined forces into tackling the content of the future of Piazza della Comunità Europea in Aprilia. Prossima Apertura is a complex process that pushes forward on the great challenges that came in front of it since its planning and up to the present day. Multidisciplinary interactions and participative co-design are a valid approach to regenerate the sensitive areas of the contemporary city, creating places with strong identity together with their inhabitants.',
  },
  '3a': {
    title: 'Brisa and Gorani Area',
    text: 'The block bounded by via Brisa and via Gorani is home to stratified memories which represent an iconic part of Milan’s history. The definition of the project created for the Gorani area is also the memory of a long process which, since the 1980s, has accompanied the development of the latest project, which finally came to fruition in 2011-2016 by local firm Cecchi & Lima Architetti Associati. The final project includes residential spaces, offices, shops and a new public square. Different eras are condensed into this single plot of land, all coexisting, creating a new urban landscape which narrates the events of its past. The actors involved in this narrative, which unfolds as one passes through the site, are the fabric of the square with the Gorani Tower, the backdrops of the new buildings which have been grafted onto the existing ones, and the underground archaeological remains. Cecchi & Lima’s design is the result of a careful reading of the existing elements and an interpretation of the public space as a series of events which are associated by the design of the paving, made from light granite slabs.',
  },
  '3b': {
    title: '8:8 Around the fountains',
    text: 'Camposaz is an initiative started in 2013, by the collaboration between two cultural associations in Primiero and Trento, with the ambition to organize wood self-construction workshops for landscape enhancement. The workshop is part of “Val di Setta slow speed”, a project aiming at slowly rediscovering the rich territory of the Setta Valley, promoted by the Union of Municipalities of Bologna’s Appennines and conducted by Ciclostile Architecture. During the Camposaz week the participants have ideated and created wood Installations, following the thematic suggestions provided by the organizers and developing ecofriendly, practical and sustainable designs to improve the quality of public spaces in the village of Borgo Serrucce. The needs highlighted during the workshops are related to enhancement of the public space identified by the presence of some old fountains in of Borgo Serrucce. The fountains were once functional elements in the daily life of the village, and have been transformed, through the construction of wood installations and urban furniture, in new relational spaces for inhabitants and tourists.',
  },
  '4a': {
    title: 'FARM Cultural Park',
    text: 'FARM Cultural Park represents an attempt at a contemporary rewriting of the ways in which people experience and transform the city, understood as a complex system, the result of countless interactions between people, spaces, events and actions. FARM sprang up in 2010 in Favara, a small town located six kilometres from the Valle dei Templi, with ancient urban roots notable for the mingling and blending of various different cultures: Greeks, Arabs, Normans and Spaniards. Two private citizens, Andrea Bartoli and Florinda Saieva, decided to spearhead the project as a way of promoting the rebirth of the town. Thus began the restoration of an entire neighbourhood, characterised by an urban morphology with Arab roots, known as Cortile Bentivegna or “I sette cortili [The Seven Courtyards]” as it consists of seven small courtyards that are home to small buildings of Arab origin. Bartoli and Saieva set up the project with the aim of promoting a transdisciplinary reading of the city capable of combining art, urban regeneration, the involvement of the local younger generations, and international intellectual excellence.',
  },
  '4b': {
    title: 'Edicola 518',
    text: 'Edicola 518, or how its founders like to call it “Four square meters of infinite space,” appears visually as a newsstand but it is in fact a gate to a new way of experiencing the space, the culture, the social life. The small settlement started as an insertion in a provincial city where it grows naturally through the flows that pass through it. Over time, it has gained new roles in the city and its collective life proving, once again, that the quality of a place sits in the added value that it brings around it. The newsstand positions itself as an interstice connecting the physical space and the world of ideas. It lives both on ground and in the virtual space and it can grow into an endless network. In the city of Perugia, the events and actions carried by Edicola 518 have worked towards encouraging citizens to become more active in the public life, and in some cases and groups, even enabling them to get involved from a state of passivity. The events, mainly announced and disseminated through the support of social media, have entered in the routine of the city life becoming a recurrent practice of the season, usually happening in the same day at the same hour every week.',
  },
  '5a': {
    title: 'Piazza Fontana',
    text: 'A public square in Rozzano, a suburb of Milan, offering the local community a flexible and shared outdoor space. The aim of this project was to create a new, flexible and welcoming landscape  for the local neighbourhood which would  satisfy the community’s complex and continuously changing needs. Labics’ intention was to make a space which would also trigger new, unplanned uses whilst retaining a strong local identity. A public consultation helped the municipality to define a very precise brief, accommodating the many different requirements and aspirations of the local community. The geometrical pattern of the landscape is based on a dense grid of ‘golden rectangles’, the dimensions of these determining every element of the square from the planting to the paving. A system of triangular shapes inside this orthogonal pattern helps to define the various natural and artificial surface treatments within the landscape, which include water, stone, lawn, shrubs and planted beds. A contemporary sculpture which acts as a gateway to the neighbourhood, benches and a pavilion/info point populate the square, providing spaces for play, relaxation and interaction.',
  },
  '5b': {
    title: 'A Natural Spring',
    text: 'The Natural Spring is situated in the small town of Santa Lucia di Serino, just outside Avellino, 40 km from Naples. This place is a destination for tourists, walkers and cyclists who stop here to drink the cool spring water in particular during the summer time. The Natural Spring is a small structure that encloses a space of about 90 sqm and is a Listed Building "Code of Cultural Heritage and Landscape". We found this Natural Spring in bad condition and it required a refurbishment project in order to prevent further and irreversible degradation. The project aims to rebuild the sense of identity and to promote the culture of participation by giving back to the community one of the main gathering places of the town, an element strongly associated with the memory and the history of this small community. In a modern concept of the relationship between the old and the new, the project is enhanced by a juxtaposition of the forms, stratigraphy, and materials of the past and the new features of the project necessary to bring to life the Spring once again.',
  },
};

export const totemTexts = {
  3: {
    text: 'Urban Rewritings',
    title:
      "An urban space can be 'interstitial' in its ambigous capability of signification in the sensemaking process: that is, in the double valence of mute void – space without any signification – or meaningful room – small but dense universe of suspension. Sometimes the project for interstitial spaces aims to transform the former into the latter: the void without signification into a significant and meaningful piece. Sometimes, on the other hand, the project transforms it into an interface, connector, exchanger, membrane, space of interrelation.",
  },
  5: {
    text: 'Architectural Thresholds',
    title:
      'An interstitial space is such if defined by different entities: architecture, natural elements, landscape artefacts, alignments and pre-established layouts. Size and shape are not important. Above all, an architectural space is such if it is without an identity. It may constitute a surprising suspension, a fracture or a change of paradigm within the continuity of the urban fabric, it may have undergone a change or denote a certain uncertainty or ambiguity of its role. In the condition of freedom from a characterization, it is ready to assume the identity of one or more elements that delimit it, or, through a design and transformative action, it can take on a new identity.',
  },
  1: {
    text: 'Green Rooms',
    title:
      'The result of a long design process, renewed in the last century (pocket parks), urban regeneration through green uses the metaphor of the domestic “living room” (Gehl 2012) as a model for the integration of different activities, individual and shared, in an open public place. Not setting aside contemporary bottom-up recovery actions, the research focuses on top-down ones, in which architects intervene on urban interstices with long-term projects, devoting them to play, socialization, plant cultivation. The small size, the strategic position —between the buildings or between the streets— and the intrinsic vocation for multifunctionality qualify the green rooms as “pulsating elements” of the city as the hours, occasions and activities change: they are measured pauses, reflective and meeting, in the daily swarming of the city.',
  },
  4: {
    text: 'Connecting Publicness',
    title:
      'The spaces of our cities have less and less a stable and defined identity once and for all and this is due to material and immaterial flows that cross them, be they made of people, things, finance, information, technologies or other. So for the contemporary city we could speak of a sort of general condition of interstitiality of space, intended as a meeting / clashing place of the different disciplines and different cultural horizons. However, what can be stated with reasonable certainty is that whatever the nature of the flows, they end in “terminals” whose form is always traceable to a space, an environment or a place with some identity of its own.',
  },
  2: {
    text: 'Ephemeral Devices',
    title:
      'Today, in the reactivation of urban interstices, although attempts are still being made at municipal and regional planning, top-down actions of urban design and management tend to recede. Rather than large-scale transformations unable to effectively deal with the dynamics of contemporary urban developments, smaller “urban catalysts” have pervasively become a preferred mode of intervention. This part of research looks at these devices both in terms of design process and products.',
  },
};

export const width = {
  1: {
    scritta1: {
      width: 0.65,
      height: 0.28,
    },
    scritta2: {
      width: 0.95,
      height: 0.55,
    },
    scritta3: {
      width: 0.95,
      height: 0.55,
    },
  },
  2: {
    scritta1: {
      width: 0.65,
      height: 0.28,
    },
    scritta2: {
      width: 0.95,
      height: 0.55,
    },
    scritta3: {
      width: 0.95,
      height: 0.55,
    },
  },
  3: {
    scritta1: {
      width: 0.65,
      height: 0.28,
    },
    scritta2: {
      width: 0.95,
      height: 0.55,
    },
    scritta3: {
      width: 0.95,
      height: 0.55,
    },
  },
  4: {
    scritta1: {
      width: 0.65,
      height: 0.28,
    },
    scritta2: {
      width: 0.95,
      height: 0.55,
    },
    scritta3: {
      width: 0.95,
      height: 0.55,
    },
  },
  5: {
    scritta1: {
      width: 0.65,
      height: 0.28,
    },
    scritta2: {
      width: 0.95,
      height: 0.55,
    },
    scritta3: {
      width: 0.95,
      height: 0.55,
    },
  },
};
