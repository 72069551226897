import { useState, lazy, Suspense } from 'react';
import close from './assets/close-white.svg';
import { isMobileWithTablet } from './utils';

const HelpViewer = ({ closeCallback }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        margin: 'auto',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3,
      }}
    >
      <div
        style={{
          height: '30vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          padding: '30px',
          color: 'white',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            margin: '10px 0',
            alignItems: 'center',
          }}
        >
          <div className={'infoIcon'} style={{ marginRight: '5px' }}></div>
          <div>
            Click on this icon to get more information about the project
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            margin: '10px 0',
            alignItems: 'center',
          }}
        >
          <div className={'plusIcon'} style={{ marginRight: '5px' }}></div>
          <div>Click on this icon to open the case study of the project</div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            margin: '10px 0',
            alignItems: 'center',
          }}
        >
          <div className={'grabIcon'} style={{ marginRight: '5px' }}></div>
          <div>Grab the screen to move around you</div>
        </div>
      </div>

      <div
        style={{
          position: 'absolute',
          bottom: isMobileWithTablet ? '18vh' : '27vh',
          right: 0,
          left: 0,
          margin: 'auto',
          width: '50px',
          borderRadius: '50px',
          backgroundColor: 'rgba(0,0,0,0.2)',
        }}
        onClick={() => closeCallback()}
      >
        <img src={close} style={{ width: '50px', cursor: 'pointer' }} />
      </div>
    </div>
  );
};

export default HelpViewer;
