import React from 'react';
import { TextureLoader, BackSide } from 'three';
import { useLoader } from 'react-three-fiber';
import view1 from './view_1.jpg';
import view2 from './view_2.jpg';
import view3 from './view_3.jpg';
import view4 from './view_4.jpg';

const ImageSphere = ({ randomIndex }) => {
  const image =
    randomIndex === 0
      ? view1
      : randomIndex === 1
      ? view2
      : randomIndex === 2
      ? view3
      : view4;
  const texture = useLoader(TextureLoader, image);

  return (
    <mesh
      position={[0, 5, 0]}
      scale={[-1, 1, 1]}
      onPointerDown={() => {
        document.body.style.cursor = 'grabbing';
      }}
      onPointerUp={() => {
        document.body.style.cursor = 'grab';
      }}
    >
      <sphereBufferGeometry attach="geometry" args={[70, 64, 64]} />
      <meshStandardMaterial
        attach="material"
        map={texture || null}
        color={'#ffffff'}
        opacity={1}
        side={BackSide}
      />
    </mesh>
  );
};

export default ImageSphere;
