import React from 'react';
import mappa from './assets/mappa.svg';
import { Link } from '@reach/router';
import MemoSvgMap from './MemoSvgMap';
import { isMobileWithTablet } from './utils';

function Home() {
  return (
    <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <div
        style={{
          position: 'absolute',
          width: isMobileWithTablet ? '80vw' : '20vw',
          height: '100vh',
          padding: '15px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          zIndex: 2,
        }}
      >
        <div className="homeTitle">
          Urban Interstices <br></br>in Italy. <br></br>{' '}
          <div className="homeSubTitle2">Design Experiences</div>
          <div className="homeSubTitle"> DAStU / Politecnico di Milano</div>
        </div>

        <div
          style={{
            color: '#458f8e',
            width: '100%',
            height: isMobileWithTablet ? '300px' : '30vh',
            fontSize: '24px',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            fontWeight: 'bold',
          }}
        >
          <Link style={{ margin: '5px 0' }} to={'/exhibit'}>
            Exhibit
          </Link>
          <Link style={{ margin: '5px 0' }} to={'/about'}>
            About
          </Link>
          <Link style={{ margin: '5px 0' }} to={'/themes'}>
            Themes
          </Link>
          <Link style={{ margin: '5px 0' }} to={'/people'}>
            People
          </Link>
          <Link style={{ margin: '5px 0' }} to={'/catalogue'}>
            Catalogue
          </Link>
          <Link style={{ margin: '5px 0' }} to={'/credits'}>
            Credits
          </Link>
        </div>
      </div>
      {!isMobileWithTablet && (
        <div
          style={{
            position: 'absolute',
            margin: '0 auto',
            fontSize: '1.7vw',
            fontWeight: 'bold',
            color: '#458f8e',
            right: 50,
            top: 50,
            pointerEvents: 'none',
            zIndex: 9999,
          }}
        >
          Click anywhere to explore
        </div>
      )}
      <div
        style={{
          position: 'absolute',
          width: '100vw',
          height: '100vh',
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: isMobileWithTablet ? '100%' : '100vw',
            height: '100%',
            margin: '0 auto',
            overflow: 'hidden',
          }}
        >
          <Link to={'/exhibit'}>
            <MemoSvgMap />
          </Link>
        </div>
      </div>{' '}
    </div>
  );
}

export default Home;
