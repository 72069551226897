import { scaleLinear } from 'd3-scale';
import * as THREE from 'three';

export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

Math.radians = function (degrees) {
  return (degrees * Math.PI) / 180;
};

const RAD2DEG = 180 / Math.PI;
const DEG2RAD = Math.PI / 180;

export const sphereRadius = 70;
export const circumference = sphereRadius * 2 * Math.PI;
export const shrinkConstant = 0.8;

const scaleY = scaleLinear().domain([0, 100]).range([100, 0]);

const fromLatToY = scaleLinear().domain([90, -90]).range([0, 100]);
const fromLngToXP = scaleLinear().domain([0, 90, 180]).range([25, 50, 75]);
const fromLngToXN = scaleLinear().domain([-180, -90, 0]).range([75, 100, 125]);

export const get3dPosition = (x, y, marker, z) => {
  //x += 0.8333;
  const lat = (scaleY(y) / (sphereRadius / 2)) * Math.PI - Math.PI / 2;
  const long = (x / (sphereRadius / 2)) * 2 * Math.PI - Math.PI;
  const distance = marker ? 30 + 0.5 * z : 40;

  //
  //   `%c lat: ${lat}, lng: ${long}`,
  //   'background: #333399  ; color: #fff',
  // );
  //
  //   `%c 3dPosition: ${
  //     Math.cos(lat) * Math.cos(long) * (sphereRadius - distance)
  //   },${Math.sin(lat) * (sphereRadius - distance)},${
  //     Math.cos(lat) * Math.sin(long) * (sphereRadius - distance)
  //   }`,
  //   'background: #333399  ; color: #fff',
  // );

  return new THREE.Vector3(
    Math.cos(lat) * Math.cos(long) * (sphereRadius - distance),
    Math.sin(lat) * (sphereRadius - distance),
    Math.cos(lat) * Math.sin(long) * (sphereRadius - distance),
  );
};

// export const cartesianToPolar = (point) => {
//   let polar = new THREE.Vector2();
//   polar.y = Math.atan2(point.x, point.z) * (180 / Math.PI);
//   const mag = new THREE.Vector2(point.x, point.z).length();
//
//   polar.x = Math.atan2(-point.y, mag) * (180 / Math.PI);
//   return polar;
// };

export const cartesianToPolar = (coord) => {
  var lon = Math.atan2(coord.x, -coord.z) * RAD2DEG;
  var length = Math.sqrt(coord.x * coord.x + coord.z * coord.z);
  var lat = Math.atan2(coord.y, length) * RAD2DEG;

  return [lon > 0 ? fromLngToXP(lon) : fromLngToXN(lon), fromLatToY(lat)];
};

export const get2dPosition = (phi, theta) => {
  if (phi && theta) {
    // const x = Math.cos(phi) * Math.cos(theta) * sphereRadius;
    // const y = Math.cos(phi) * Math.sin(theta) * sphereRadius;
    // const z = Math.sin(phi) * sphereRadius;

    // return { x: x, y: y };

    //const phi = (90 - lat) * DEG2RAD;
    //const theta = (lon + 180) * DEG2RAD;

    return {
      x: -(Math.sin(phi) * Math.sin(theta)),
      y: Math.cos(phi),
      z: Math.sin(phi) * Math.cos(theta),
    };
  }
};

export const vectolatlng = (vector3) => {
  vector3.normalize();

  //longitude = angle of the vector around the Y axis
  //-( ) : negate to flip the longitude (3d space specific )
  //- PI / 2 to face the Z axis
  var lng = -Math.atan2(-vector3.z, -vector3.x) - Math.PI / 2;

  //to bind between -PI / PI
  if (lng < -Math.PI) lng += Math.PI * 2;

  //latitude : angle between the vector & the vector projected on the XZ plane on a unit sphere

  //project on the XZ plane
  var p = new THREE.Vector3(vector3.x, 0, vector3.z);
  //project on the unit sphere
  p.normalize();

  //commpute the angle ( both vectors are normalized, no division by the sum of lengths )
  var lat = Math.acos(p.dot(vector3));

  //invert if Y is negative to ensure teh latitude is comprised between -PI/2 & PI / 2
  if (vector3.y < 0) lat *= -1;

  return [lng, lat];
};

export const getDimension = (width, height) => {
  const w = width * (circumference / 100) * shrinkConstant;
  const h = height * (circumference / 2 / 100) * shrinkConstant;
  return [w, h];
};

export const getObjectRotation = (vectorA, vectorB, sign) => {
  // Store some information about them for below
  const dot = vectorA.dot(vectorB);
  const lengthA = vectorA.length();
  const lengthB = vectorB.length();
  // Now to find the angle
  const theta = Math.acos(dot / (lengthA * lengthB));
  return theta * sign;
};

export const focalPoint = new THREE.Vector3(0, 0, 0);
