import { useState, lazy, Suspense } from 'react';
import arrowLeft from './assets/freccia-sx.svg';
import arrowRight from './assets/freccia-dx.svg';
import close from './assets/close-white.svg';
import ReactPlayer from 'react-player';
import { isMobileWithTablet } from './utils';

const CaseViewer = ({ caseIndex, closeCallback }) => {
  let hasVideo = false;
  let videoUrl = null;

  if (caseIndex === 1) {
    hasVideo = true;
    videoUrl = 'https://vimeo.com/531815686';
  }

  if (caseIndex === 8) {
    hasVideo = true;
    videoUrl = 'https://vimeo.com/536331404';
  }

  if (caseIndex === 9) {
    hasVideo = true;
    videoUrl = 'https://vimeo.com/533506473';
  }

  const [index, setIndex] = useState(1);
  const [actualImage, setActualImage] = useState(1);
  const maxIndex =
    caseIndex === 8 ? 8 : hasVideo ? 10 : caseIndex === 4 ? 11 : 10;

  if (index > 0 && index < maxIndex) {
    import(`./assets/caso${caseIndex}/img_${caseIndex}_${index}.jpg`).then(
      (image) => {
        setActualImage(image.default);
      },
    );
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        margin: 'auto',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3,
      }}
    >
      <div onClick={() => setIndex((i) => (i <= 1 ? 1 : i - 1))}>
        <img
          src={arrowLeft}
          style={{
            width: '50px',
            cursor: 'pointer',
            opacity: index === 0 ? 0.25 : 1,
          }}
        />
      </div>
      <div
        style={{
          height: isMobileWithTablet ? '30vh' : '70vh',
        }}
      >
        <Suspense
          fallback={
            '<div style={{ color: "white", position: "absolute", top: "50%", left: "50%", zIndex: 9999 }}>Loading...</div>'
          }
        >
          {hasVideo && index === maxIndex ? (
            <ReactPlayer
              url={videoUrl}
              width={window.innerHeight * 1}
              height={window.innerHeight * 0.7}
              forceAudio
              controls
              style={{
                position: 'relative',
              }}
            />
          ) : (
            <img
              src={actualImage}
              style={{ height: '100%', pointerEvents: 'none' }}
            />
          )}
        </Suspense>
        <div style={{ color: 'white' }}>
          {Math.min(index, hasVideo ? maxIndex : maxIndex - 1)} /{' '}
          {hasVideo ? maxIndex : maxIndex - 1}
        </div>
      </div>
      <div
        onClick={() =>
          setIndex((i) =>
            i < (hasVideo ? maxIndex : maxIndex - 1) ? i + 1 : maxIndex,
          )
        }
      >
        <img
          src={arrowRight}
          style={{
            width: '50px',
            cursor: 'pointer',
            opacity: index === maxIndex ? 0.25 : 1,
            pointerEvents:
              index === (hasVideo ? maxIndex : maxIndex - 1) ? 'none' : 'auto',
          }}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: isMobileWithTablet ? '18vh' : '5vh',
          right: 0,
          left: 0,
          margin: 'auto',
          width: '100px',
        }}
        onClick={() => closeCallback()}
      >
        <img src={close} style={{ width: '50px', cursor: 'pointer' }} />
      </div>
    </div>
  );
};

export default CaseViewer;
