import React from 'react';
import { Link } from '@reach/router';

function Themes() {
  return (
    <div
      style={{ backgroundColor: '#458f8e', width: '100%', minHeight: '100%' }}
    >
      <Link to={'/home'}>
        <div className="backIcon">&#x2190;</div>
      </Link>
      <main>
        <div
          style={{
            color: 'white',
          }}
        >
          <h1>Themes</h1>
          <br></br>
          <h2>Urban Rewritings</h2>
          <p>
            An urban space can be “interstitial” in its ambigous capability of
            signification in the sensemaking process: that is, in the double
            valence of mute void space without any signification −or meaningful
            room− small but dense universe of suspension. Sometimes the project
            for interstitial spaces aims to transform the former into the
            latter: the void without signification into a significant and
            meaningful piece. Sometimes, on the other hand, the project
            transforms it into an interface, connector, exchanger, membrane,
            space of interrelation.
          </p>
          <br></br>
          <h2>Architectural Thresholds</h2>
          <p>
            An interstitial space is such if defined by different entities:
            architecture, natural elements, landscape artefacts, alignments and
            pre-established layouts. Size and shape are not important. Above
            all, an architectural space is such if it is without an identity. It
            may constitute a surprising suspension, a fracture or a change of
            paradigm within the continuity of the urban fabric, it may have
            undergone a change or denote a certain uncertainty or ambiguity of
            its role. In the condition of freedom from a characterization, it is
            ready to assume the identity of one or more elements that delimit
            it, or, through a design and transformative action, it can take on a
            new identity.
          </p>
          <br></br>
          <h2>Green Rooms</h2>
          <p>
            The result of a long design process, renewed in the last century
            (pocket parks), urban regeneration through green uses the metaphor
            of the domestic “living room” (Gehl 2012) as a model for the
            integration of different activities, individual and shared, in an
            open public place. Not setting aside contemporary bottom-up recovery
            actions, the research focuses on top-down ones, in which architects
            intervene on urban interstices with long-term projects, devoting
            them to play, socialization, plant cultivation. The small size, the
            strategic position −between the buildings or between the streets−
            and the intrinsic vocation for multifunctionality qualify the green
            rooms as “pulsating elements” of the city as the hours, occasions
            and activities change: they are measured pauses, reflective and
            meeting, in the daily swarming of the city.
          </p>
          <br></br>
          <h2>Connecting Publicness</h2>
          <p>
            The spaces of our cities have less and less a stable and defined
            identity once and for all and this is due to material and immaterial
            flows that cross them, be they made of people, things, finance,
            information, technologies or other. So for the contemporary city we
            could speak of a sort of general condition of interstitiality of
            space, intended as a meeting / clashing place of the different
            disciplines and different cultural horizons. However, what can be
            stated with reasonable certainty is that whatever the nature of the
            flows, they end in “terminals” whose form is always traceable to a
            space, an environment or a place with some identity of its own.
          </p>
          <br></br>
          <h2>Ephemeral Devices</h2>
          <p>
            Today, in the reactivation of urban interstices, although attempts
            are still being made at municipal and regional planning, top-down
            actions of urban design and management tend to recede. Rather than
            large-scale transformations unable to effectively deal with the
            dynamics of contemporary urban developments, smaller “urban
            catalysts” have pervasively become a preferred mode of intervention.
            This part of research looks at these devices both in terms of design
            process and products.
          </p>
        </div>
      </main>
    </div>
  );
}

export default Themes;
