import React from 'react';
import { Link } from '@reach/router';

function Credits() {
  return (
    <div
      style={{ backgroundColor: '#458f8e', width: '100%', minHeight: '100%' }}
    >
      <Link to={'/home'}>
        <div className="backIcon">&#x2190;</div>
      </Link>
      <main>
        <div
          style={{
            color: 'white',
          }}
        >
          <h1>Credits</h1>
          <br></br>
          <p>
            <a
              href="https://www.dastu.polimi.it/interstizi-e-fragilita-urbane-il-caso-italia-la-prospettiva-europea/"
              target="blank"
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              Ex-Farb 2018
            </a>
            , DAStU - Politecnico di Milano, 2019-2020 Funded Programme
          </p>

          <p>
            <strong>Scientific Responsible:</strong> Imma Forino
          </p>

          <p>
            <strong>Research Group:</strong> Michela Bassanelli, Luca Basso
            Peressut, Bertrando Bonfantini, Madalina Ghibusi, Marco Introini,
            Jacopo Leveratto, Pierluigi Salvadeo, Luigi Spinelli
          </p>

          <p>
            <strong>Exhibition and graphic design:</strong>{' '}
            <a
              href="https://www.contentscontexts.it/"
              target="_blank"
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              Michela Bassanelli
            </a>
          </p>

          <p>
            <strong>Web development: </strong>
            <a
              href="https://www.encoded.studio"
              target="_blank"
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              Paolo Corti
            </a>
          </p>

          <p>
            <strong>Visual Narration: </strong>
            <a
              href="http://www.marcointroini.net/"
              target="_blank"
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              Marco Introini
            </a>
          </p>

          <p>
            <strong>Projects, images and drawings by:</strong>
            <br></br>

            <a
              href="https://assemblestudio.co.uk/"
              target="_blank"
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              ASSEMBLE STUDIO
            </a>
            <br></br>
            <a
              href="https://www.camposaz.com/"
              target="_blank"
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              CAMPOSAZ
            </a>
            <br></br>
            <a
              href="https://modulo.net/files/chunks/588622e90fc46e0c6e8b457f/single-fullw_5a1544c3a0d55638e6000012.jpg"
              target="_blank"
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              {'CECCHI & LIMA ARCHITETTI ASSOCIATI'}
            </a>
            <br></br>
            <a
              href="https://www.emergenzeweb.it/edicola-518/"
              target="_blank"
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              EMERGENZE
            </a>
            <br></br>
            <a
              href="https://www.labics.it/"
              target="_blank"
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              LABICS
            </a>
            <br></br>
            <a
              href="https://www.lapsarchitecture.com/"
              target="_blank"
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              LAPS ARCHITECTURE
            </a>
            <br></br>
            <a
              href="https://divisare.com/authors/888909246-vincenzo-latina"
              target="_blank"
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              VINCENZO LATINA
            </a>
            <br></br>
            <a
              href="https://www.orizzontale.org/"
              target="_blank"
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              ORIZZONTALE
            </a>
            <br></br>
            <a
              href="http://www.studionowa.com/"
              target="_blank"
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              NOWA
            </a>
            <br></br>
            <a
              href="https://www.32mq.co.uk/"
              target="_blank"
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              32mq DESIGN STUDIO
            </a>
          </p>
        </div>
      </main>
    </div>
  );
}

export default Credits;
