import React, { useEffect, useRef, useState } from 'react';
import bottom from './assets/textures/bottom.jpg';
import group1 from './assets/textures/group1.jpg';
import quadrato from './assets/textures/quadrato.jpg';
import map from './assets/textures/mappa.jpg';
import { useLoader } from 'react-three-fiber';
import { TextureLoader, RGBAFormat, DoubleSide, Vector3 } from 'three';
import { Html } from '@react-three/drei';
import plusIcon from './assets/plus.svg';
import closeGreen from './assets/close-green.svg';

const texts = {
  title: 'Italian Interstice Design Map',
  text:
    'The first phase of the research has focused on the construction of a map that collects almost seventies paradigmatic examples, on the Italian territory, from 2000 to the present day, grouped according to five analytical categories related to practices of regeneration processes: Urban Rewriting, Connecting Publicness, Architectural Threshold, Green Rooms, Ephemeral Devices. In order to draw an atlas of design references, the map was organised through a taxonomic model composed of "reconnaissance sheets", which collect the  information about the projects, the architects, the dimensions of the interstices and the past and recent uses.',
};

const focalPoint = new Vector3(0, 0, 0);

const TotemMap = ({
  position,
  openMapCallback,
  size,
  totemIndex,
  rotation,
}) => {
  const [info, setInfo] = useState(false);
  const bottomTexture = useLoader(TextureLoader, bottom);
  const group1Texture = useLoader(TextureLoader, group1);
  const quadratoTexture = useLoader(TextureLoader, quadrato);
  const mapTexture = useLoader(TextureLoader, map);

  return (
    <group rotation={[0, rotation, 0]} position={[0, -3, 0]}>
      <mesh
        position={[position[0], -size * 4 + size * 4.4, position[2]]}
        rotation={[0, 0, 0]}
      >
        <boxBufferGeometry args={[size, size * 0.3, size]} />
        <meshStandardMaterial color="#fff" map={bottomTexture} />
      </mesh>

      <mesh
        position={[position[0], -size * 4 + size * 3.85, position[2]]}
        rotation={[0, 0, 0]}
      >
        <boxBufferGeometry args={[size, size * 0.8, size]} />
        <meshStandardMaterial color="#458f8e" />

        {info && (
          <Html position={[10, 3, 0]} zIndexRange={[1, 0]}>
            <div
              style={{
                borderRadius: '20px',
                border: '2px solid #458f8e',
                color: '#458f8e',
                width: '380px',
                backgroundColor: 'white',
                padding: '30px',
                position: 'relative',
              }}
            >
              <h3 style={{ marginTop: 0 }}>{texts.title}</h3>
              <div>{texts.text}</div>
              <img
                src={closeGreen}
                width={50}
                onClick={() => setInfo(null)}
                onPointerDown={() => setInfo(null)}
                style={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  cursor: 'pointer',
                }}
              />
            </div>
          </Html>
        )}
      </mesh>
      <mesh
        position={[position[0], -size * 4 + size * 2.45, position[2]]}
        rotation={[0, 0, 0]}
      >
        <boxBufferGeometry args={[size, size * 2, size]} />
        <meshStandardMaterial color="#458f8e" />
      </mesh>
      <mesh
        position={[position[0], -size * 4 + size * 1.2, position[2]]}
        rotation={[0, 0, 0]}
      >
        <boxBufferGeometry args={[size, size / 2, size]} />
        <meshStandardMaterial map={bottomTexture} color="#fff" />
      </mesh>

      {/* TESTI */}
      <mesh
        position={[
          position[0],
          -size * 4 + size * 2.5 + 3.7,
          position[2] + size / 2 + 1,
        ]}
        rotation={[0, 0, 0]}
      >
        <planeGeometry args={[size * 0.98, size * 2.2]} />
        <meshStandardMaterial map={mapTexture} color="white" />

        <Html position={[-8, 0, 0]} zIndexRange={[1, 0]}>
          <div
            className={'infoIcon'}
            width={35}
            onClick={() => setInfo(true)}
            onPointerDown={() => setInfo(true)}
            style={{ cursor: 'pointer' }}
          />
          <div
            className={'plusIcon'}
            width={35}
            onClick={() => {
              openMapCallback();
              setInfo(null);
            }}
            onPointerDown={() => {
              openMapCallback();
              setInfo(null);
            }}
            style={{ cursor: 'pointer' }}
          />
        </Html>
      </mesh>

      {/* BASE */}

      <mesh
        position={[position[0], -size * 4 + size * 1.01, position[2]]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <planeGeometry args={[size * 1.75, size * 1.75]} />
        <meshStandardMaterial map={quadratoTexture} side={DoubleSide} />
      </mesh>

      <mesh
        position={[position[0], -size * 4 + size * 0.8, position[2]]}
        rotation={[0, 0, 0]}
      >
        <boxBufferGeometry args={[size * 1.75, size * 0.4, size * 1.75]} />
        <meshStandardMaterial map={bottomTexture} />
      </mesh>
    </group>
  );
};

export default TotemMap;
