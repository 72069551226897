import React from 'react';
import { Link } from '@reach/router';

function Catalogue() {
  return (
    <div
      style={{ backgroundColor: '#458f8e', width: '100%', minHeight: '100%' }}
    >
      <Link to={'/home'}>
        <div className="backIcon">&#x2190;</div>
      </Link>
      <main>
        <div
          style={{
            color: 'white',
          }}
        >
          <h1>Catalogue</h1>
          <br></br>
          <p>
            This publication has received financial support from the DAStU
            “Fragilità Territoriali” Research Project funded by the Italian
            Ministry of Education, Universities and Research (MIUR), Departments
            of Excellence Initiative 2018-2022 <br></br>
            <br></br>
            <strong>Urban Interstices in Italy: Design Experiences</strong>{' '}
            <br></br>
            Edited by Bertrando Bonfantini and Imma Forino<br></br>{' '}
            LetteraVentidue, 2021
          </p>
        </div>
      </main>
    </div>
  );
}

export default Catalogue;
