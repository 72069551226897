import React from 'react';
import { Link } from '@reach/router';

function About() {
  return (
    <div
      style={{ backgroundColor: '#458f8e', width: '100%', minHeight: '100%' }}
    >
      <Link to={'/home'}>
        <div className="backIcon">&#x2190;</div>
      </Link>
      <main>
        <div
          style={{
            color: 'white',
          }}
        >
          <h1>About</h1>
          <br></br>
          <h2>Research Project</h2>
          <p>
            <strong>
              Interstices and Urban Fragilities: The Italian Experience, the
              European Outlook
            </strong>{' '}
            is a research project aimed at tracing, reading and narrating a
            meaningful selection of regeneration, re-appropriation and
            domestication of Italian urban interstices: small public areas,
            mostly neglected or degraded, which represent the residue of
            heterogeneous processes of discontinuity, fragmentation and
            marginalisation in the production of space. It’s in these places,
            where, the contemporary city reveals an authentic sociability not
            being constrained by the rules of the traditionally recognized
            public spaces. Both qualitative and observational studies show how,
            in the urban interstices, people behave more freely in their
            interaction resulting in an appropriation of these newfound spaces.
          </p>
          <br></br>
          <h2>Virtual Exhibition</h2>
          <h3>Findings</h3>
          <p>
            The exhibition{' '}
            <strong>Urban Interstices in Italy: Design Experiences</strong> aim
            to build an atlas of possible methodological approaches through
            which the design of public space can allow and encourage different
            forms of spatial appropriation of urban interstices, thus leading
            people to take care of them. The exhibition shows the process of
            mapping almost 60 paradigmatic Italian interstices, from 2000 to the
            present day, grouped according to five analytical categories aimed
            at identifying as many regeneration processes: Urban Rewriting,
            Connecting Publicness, Architectural Threshold, Green Rooms and
            Ephemeral Devices. The most significant and wide-ranging section is
            dedicated to the representation of 10 projects, representing
            meaningful approaches to regeneration and re-appropriation of urban
            interstices. The main object is to highlight some concrete tools to
            deal with contemporary processes of physical and social
            transformation of these spaces by making them available to the
            scientific community and urban institutions.
          </p>
          <br></br>
          <h3>Description of the exhibition</h3>
          <p>
            The exhibition opens with an imaginary map that virtually links the
            10 survey sites. By clicking on the map, it’s possible to access
            four urban spaces selected from the 10 investigated. The intention
            expressed in this action is to transport the visitors directly into
            the urban space, as they could enjoy the exhibition in the central
            places of the project. The contents are communicated through five
            interactive totems, divided according to the analytical categories
            of the project. Each totem narrates two case studies through
            drawings, images and audio or video interviews. The contents are
            visualised by means of immersion in the urban space, which becomes
            the setting for the explanatory panels. The sixth totem is dedicated
            to the visualisation of the general mapping work, which gathers
            together about 60 paradigmatic examples of regeneration of italian
            urban interstices. Finally, through a virtual screen, it’s possible
            to access the research of the photographer Marco Introini, who has
            created a personal narrative account of these interstices.
          </p>
        </div>
      </main>
    </div>
  );
}

export default About;
